export default {
  'home.name': 'Home page',
  'home.title': 'Program - Activity',
  'home.fileName': 'File name',
  'home.fullPath': 'Path',
  'home.contentType': 'File type',
  'home.createdAt': 'Date created',
  'home.confirm.title.delete': 'Delete the file',
  'home.confirm.content.delete': 'Do you agree to delete these files?',
  'home.create': 'Add new file',
  'home.action': 'Action',
  'home.memberSelect': 'Members participating',
  'home.memberSelect-placeholder': 'Select members participating',
  'home.member': 'Member',
  'home.represents': 'Represents',
  'home.memberAndPartner': 'Executive committee',
  'home.clubs': 'Clubs',
  'home.accepts': 'Members participating',
};
