import { routerFormAction, routerFormActionSub } from '@view/Action/component/FromAction/router';
import { routerActionListMember } from '@view/Action/component/ListMember/router';
/* eslint-disable @typescript-eslint/comma-dangle */
import { routerAction } from '@view/Action/router';
import { routerForgot, routerForgotToken } from '@view/Auth/ForgotPassword/router';
import { routerLogin, routerLoginHome } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profile/router';
import { routerResetForgotPass } from '@view/Auth/ResetForgotPass/router';
import {
  routerCalendar,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
} from '@view/Calendar/router';
import { routerCategory } from '@view/Category/router';
import { routerFormClubs, routerFormClubsAdd } from '@view/Clubs/component/FormClubs/router';
import {
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
} from '@view/Clubs/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerListExecutiveCommittee } from '@view/Clubs/component/ListExecutiveCommittee/router';
import {
  routerFormClubsMember,
  routerFormClubsMemberInfo,
} from '@view/Clubs/component/ListMembers/FormMember/router';
import { routerListClubsMemberTab } from '@view/Clubs/component/ListMembers/router';
import { routerResultSearchPage } from '@view/Clubs/component/ResultSearchPage/router';
import { routerClubs } from '@view/Clubs/router';
import { routerContacts } from '@view/Contacts/router';
import { routerDetailFeedbackPage } from '@view/Feedback/component/DetailPage/router';
import { routerFeedback } from '@view/Feedback/router';
import { routerHome } from '@view/Home/router';
import { routerLaudatoryDetailPage } from '@view/Laudatory/component/DetailPage/router';
import { routerLaudatoryTabs } from '@view/Laudatory/router';
import {
  routerFormExecutiveCommittee,
  routerFormExecutiveCommitteeInfo,
} from '@view/MemberAndPartner/FormExecutiveCommittee/router';
import { routerMemberAndPartnerTabs } from '@view/MemberAndPartner/router';
import { routerMeritBadges } from '@view/MeritBadges/router';
import {
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
} from '@view/Notification/router';
import { routerPageError } from '@view/PageError/router';
import { routerReports } from '@view/Reports/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerSetting } from '@view/Setting/router';
import { routerSponsor } from '@view/Sponsor/router';
import { routerUser } from '@view/User/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerViewProfile,
  routerHome,
  routerUser,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerClubs,
  routerMemberAndPartnerTabs,
  routerListExecutiveCommittee,
  routerListClubsMemberTab,
  routerAction,
  routerLaudatoryTabs,
  routerSponsor,
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
  routerFeedback,
  routerDetailFeedbackPage,
  routerCalendar,

  routerReports,

  routerFormClubsAdd,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerCategory,
  routerFormExecutiveCommittee,

  routerSetting,
  routerFormExecutiveCommitteeInfo,

  routerFormClubs,
  routerContacts,
  routerFormClubsMember,
  routerFormClubsMemberInfo,
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,

  routerFormAction,
  routerActionListMember,
  routerLaudatoryDetailPage,
  routerFormActionSub,
  routerMeritBadges,
  routerResultSearchPage,

  routerPageError,
];

export const publicPage: IRouter[] = [
  routerLoginHome,
  routerLogin,
  routerForgot,
  routerForgotToken,
  routerResetForgotPass,
  routerPageError,
];
