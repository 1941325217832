import viVN from 'antd/lib/locale/vi_VN';

import action from './action';
import auth from './auth';
import calendar from './calendar';
import careers from './careers';
import category from './category';
import certificates from './certificates';
import clubs from './clubs';
import common from './common';
import contacts from './contacts';
import cost from './cost';
import enterprises from './enterprises';
import feedback from './feedback';
import fields from './fields';
import Form from './form';
import home from './home';
import laudatory from './laudatory';
import markets from './markets';
import memberAndPartner from './memberAndPartner';
import meritBadges from './meritBadges';
import messAlert from './messAlert';
import notification from './notification';
import organizations from './organizations';
import pageError from './pageError';
import profile from './profile';
import reports from './reports';
import roles from './roles';
import server from './server';
import setting from './setting';
import sponsor from './sponsor';
import targetCustomers from './targetCustomers';
import user from './user';

export default {
  ...viVN,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...user,
  ...sponsor,
  ...action,
  ...laudatory,
  ...cost,
  ...profile,
  ...messAlert,
  ...memberAndPartner,
  ...notification,
  ...calendar,
  ...careers,
  ...certificates,
  ...markets,
  ...targetCustomers,
  ...organizations,
  ...enterprises,
  ...fields,
  ...category,
  ...setting,
  ...clubs,
  ...home,
  ...contacts,
  ...reports,
  ...meritBadges,
  ...feedback,

  Form,
};
