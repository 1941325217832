import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsMember: IRouter = {
  path: '/clubs/list-members/form-member/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'member.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.CLUBS_CREATE_MEMBER,
};

export const routerFormClubsMemberInfo: IRouter = {
  path: '/clubs/list-members/form-member/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'member.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.CLUBS_UPDATE_MEMBER,
};
