export default {
  'reports.name': 'Report',
  'reports.title': 'Input',
  'reports.nameFile': 'File name',
  'reports.createdAt': 'Create day',
  'reports.reporter': 'Reporter',
  'reports.create': 'Add report',
  'reports.delete.title': 'Delete report',
  'reports.delete.content': 'Do you want to delete this report?',
  'report.clubsName': 'Club name',
};
