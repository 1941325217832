export default {
  'meritBadges.name': 'Chuyên hiệu',
  'meritBadges.code': 'Mã chuyên hiệu',
  'meritBadges.meritName': 'Tên chuyên hiệu',
  'meritBadges.description': 'Mô tả',
  'meritBadges.create': 'Tạo chuyên hiệu',
  'meritBadges.information': 'Xem chi tiết chuyên hiệu',
  'meritBadges.update': 'Chỉnh sửa chuyên hiệu',
  'meritBadges.delete.title': 'Xóa chuyên hiệu',
  'meritBadges.delete.content': 'Bạn có muốn xóa chuyên hiệu đã chọn?',
};
