import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsExecutiveCommittee: IRouter = {
  path: '/clubs/list-members/form-executive-committee/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'represents.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REPRESENTS_VIEW_FORM,
};

export const routerFormClubsExecutiveCommitteeInfo: IRouter = {
  path: '/clubs/list-members/form-executive-committee/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'represents.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REPRESENTS_VIEW_FORM,
};
