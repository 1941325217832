export default {
  'sponsor.name': 'Contribute',
  'sponsor.title': 'Contribute List',
  'sponsor.titleDatePicker': 'Contribution Time',
  'sponsor.create': 'Add contribute',
  'sponsor.information': 'Details of contributions',
  'sponsor.member': 'Member name',
  'sponsor.unit': 'Unit',
  'sponsor.product': 'Product contribute',
  'sponsor.unitPrice': 'Unit Price',
  'sponsor.price': 'Price',
  'sponsor.quantity': 'Quantity',
  'sponsor.group': 'Recipient',
  'sponsor.action': 'Action',
  'sponsor.user': 'Recipient',
  'sponsor.intoMoney': 'Into Money (VND)',
  'sponsor.sponsorDate': 'Contribute date',
  'sponsor.code': 'Member code',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Pay day',
  'sponsor.content': 'Content',
  'sponsor.attach': 'Attach',
  'sponsor.tagName': 'Contributors',
  'sponsor.programCode': 'Program Code',
  'sponsor.delete.title': 'Contribute remove',
  'sponsor.delete.content': 'Do you want to remove this contribute?',
  'sponsors.tagName': 'Membership name',
};
