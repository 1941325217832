export default {
  'user.name': 'Người dùng',

  'user.username': 'Tên đăng nhập',
  'user.emailAddress': 'Email người dùng',
  'user.createdAt': 'Ngày tạo',
  'user.information': 'Thông tin người dùng',
  'user.update': 'Chỉnh sửa thông tin người dùng',
  'user.action': 'Hành động',
  'user.create': 'Thêm người dùng',
  'user.fullName': 'Tên người dùng',
  'user.password': 'Mật khẩu',
  'user.dayOfBirth': 'Ngày sinh',
  'user.status': 'Trạng thái',
  'user.avatar': 'Hình đại diện',
  'user.phoneNumber': 'Số điện thoại',
  'user.operationStatus': 'Trạng thái',
  'user.title': 'Quản lý người dùng',
  'user.clubId': 'Liên Đội',
  'user.delete.title': 'Xóa người dùng',
  'user.delete.content': 'Bạn có muốn xóa những người dùng này',
};
