import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerListClubsMemberTab: IRouter = {
  path: '/clubs/list-members/:id',
  loader: React.lazy(() => import('./index')), /// danh sách đội viện
  exact: true,
  activePath: '/clubs/list-members/',
  menu: {
    icon: <Icon.Users />,
  },
  generateMenu(id) {
    return Boolean(id);
  },
  generatePath(id) {
    return `/clubs/list-members/${id}`;
  },
  name: 'clubs.list-members', //translate here for breadcrumb and sidebar
};
