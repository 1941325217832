import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerLaudatoryDetailPage: IRouter = {
  path: '/laudatory/:tab/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'laudatory.detailPage', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REWARDS_SUGGEST_DETAIL_PAGE,
};
