export default {
  'user.name': 'User',
  'user.username': 'User name',
  'user.emailAddress': 'Email address',
  'user.createdAt': 'Date created',
  'user.information': 'User information',
  'user.update': 'Update user information',
  'user.action': 'Action',

  'user.create': 'Add users',

  'user.password': 'Password',
  'user.dayOfBirth': 'Day of birth',
  'user.status': 'Status',
  'user.fullName': 'Full name',
  'user.avatar': 'Avatar',
  'user.phoneNumber': 'Phone number',
  'user.operationStatus': 'Status',
  'user.title': 'User Management',
  'user.clubId': 'Club',
  'user.delete.title': 'Delete users',
  'user.delete.content': 'Do you have to erase these users',
};
