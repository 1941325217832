export default {
  'feedback.name': 'Phản ánh - kiến nghị',
  'feedback.heading': 'Danh sách phản ánh - kiến nghị',
  'feedback.code': 'Mã phản ánh',
  'feedback.title': 'Tiêu đề',
  'feedback.content': 'Nội dung vấn đề',
  'feedback.creator': 'Người tạo',
  'feedback.clubCreator': 'Thuộc Liên Đội',
  'feedback.createdAt': 'Ngày tạo',
  'feedback.status': 'Trạng thái',
  'feedback.status.param':
    '{status,select, 1 {Đã phản hồi} 2 {Chưa phản hồi} other {Không xác định}}',
  'feedback.action': 'Hành động',
  'feedback.status.replied': 'Đã phản hồi',
  'feedback.status.un-replied': 'Chưa phản hồi',
  'feedback.belongClubIds': 'Thuộc Liên Đội',
  'feedback.detailPage.title': 'Chi tiết Phản ánh - kiến nghị',
  'feedback.detailPage.creator': 'Người phản ánh',
  'feedback.detailPage.phoneNumber': 'Số điện thoại',
  'feedback.detailPage.dateCreator': 'Ngày phản ánh',
  'feedback.detailPage.reply': 'Trả lời phản hồi',
  'feedback.detailPage.contentText': 'Nội dung vấn đề',
  'feedback.detailPage.contentFile': 'Nội dung đính kèm',
  'feedback.detailPage.download': 'Tải xuống',
  'feedback.reply': 'Gửi phản hồi',
  'feedback.attachFile': 'Tệp đính kèm',
  'feedback.send': 'Gửi',
  'feedback.detailPage.contentReply': 'Nội dung phản hồi',
  'feedback.detailPage.contentReplyTime': 'Thời gian phản hồi',
  'feedback.by': ' bởi',
  'feedback.info': 'Chi tiết Phản ánh - kiến nghị',
  'feedback.time': 'Ngày tạo',
  'feedback.startDay': 'Từ ngày',
  'feedback.endDay': 'Đến ngày',
};
