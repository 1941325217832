export default {
  'feedback.name': 'Feedback',
  'feedback.heading': 'List of feedback',
  'feedback.code': 'Feedback code',
  'feedback.title': 'Title',
  'feedback.content': 'Content',
  'feedback.creator': 'Creator',
  'feedback.clubCreator': 'Belong club',
  'feedback.createdAt': 'Created at',
  'feedback.status': 'Status',
  'feedback.status.param': '{status,select, 1 {Replied} 2 {Not replied} other {Undefined}}',
  'feedback.action': 'Action',
  'feedback.status.replied': 'Replied',
  'feedback.status.un-replied': 'Not replied',
  'feedback.belongClubIds': 'Belong club',
  'feedback.detailPage.title': 'Feedback detail',
  'feedback.detailPage.creator': 'Feedback creator',
  'feedback.detailPage.phoneNumber': 'Phone number',
  'feedback.detailPage.dateCreator': 'Feedback date',
  'feedback.detailPage.reply': 'Reply status',
  'feedback.detailPage.contentText': 'Content',
  'feedback.detailPage.contentFile': 'Attach files',
  'feedback.detailPage.download': 'Download',
  'feedback.reply': 'Reply',
  'feedback.attachFile': 'Attach files',
  'feedback.send': 'Send',
  'feedback.detailPage.contentReply': 'Reply content',
  'feedback.detailPage.contentReplyTime': 'Reply at',
  'feedback.by': 'by',
  'feedback.info': 'Detail feedback',
  'feedback.time': 'Create',
  'feedback.startDay': 'From',
  'feedback.endDay': 'To',
};
