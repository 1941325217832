import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormAction: IRouter = {
  path: '/action/:level/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  // name: 'formAction.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.ACTIVITIES_VIEW_FORM,
};

export const routerFormActionSub: IRouter = {
  path: '/action/:level/:type/:id/:sub',
  loader: React.lazy(() => import('./index')),
  exact: true,
  // name: 'formAction.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.ACTIVITIES_SUB_VIEW_FORM,
};
