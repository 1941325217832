export default {
  'home.name': 'Trang Chủ',
  'home.title': 'Chương trình - Hoạt động',
  'home.fileName': 'Tên File',
  'home.fullPath': 'Đường dẫn',
  'home.contentType': 'Loại file',
  'home.createdAt': 'Ngày tạo',
  'home.confirm.title.delete': 'Xóa File',
  'home.confirm.content.delete': 'Bạn đồng ý xóa những file này ?',
  'home.create': 'Thêm mới file',
  'home.action': 'Hành Động',
  'home.memberSelect': 'Đối tượng',
  'home.memberSelect-placeholder': 'Chọn đối tượng',
  'home.member': 'Đội viên',
  'home.represents': 'Phụ trách đội',
  'home.memberAndPartner': 'Ban chấp hành',
  'home.clubs': 'Liên Đội',
  'home.accepts': 'Đối tượng',
};
