import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerMeritBadges: IRouter = {
  path: '/merit-badges',
  loader: React.lazy(() => import('./index')),
  exact: true,
  icon: <Icon.CreditCard />,
  name: 'meritBadges.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.MERITBADGES_SEARCH,
};
