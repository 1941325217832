export default {
  'meritBadges.name': 'Merit badges',
  'meritBadges.code': 'Merit badges code',
  'meritBadges.meritName': 'Merit badges name',
  'meritBadges.description': 'Description',
  'meritBadges.create': 'Create merit badges',
  'meritBadges.information': 'View detail merit badges',
  'meritBadges.update': 'Update merit badges',
  'meritBadges.delete.title': 'Delete merit badges',
  'meritBadges.delete.content': 'Do you want to delete this merit badges?',
};
