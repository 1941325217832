export default {
  'memberAndPartner.name': 'Ban chấp hành',
  'memberAndPartner.title': 'Ban chấp hành',
  'executiveCommittee.code': 'Mã ban chấp hành',
  'executiveCommittee.idCard': 'Mã thẻ',
  'executiveCommittee.name': 'Họ và tên',
  'executiveCommittee.dayOfBirth': 'Ngày sinh',
  'executiveCommittee.gender': 'Giới tính',
  'executiveCommittee.phoneNumber': 'Số điện thoại',
  'executiveCommittee.emailAddress': 'Email',
  'executiveCommittee.avatar': 'Ảnh đại diện',
  'executiveCommittee.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'executiveCommittee.title-1': 'Thông tin cá nhân',
  'executiveCommittee.add': 'Thêm ban chấp hành',
  'executiveCommittee.view': 'Chi tiết ban chấp hành',
  'executiveCommittee.edit': 'Cập nhật ban chấp hành',
  'executiveCommittee.title-2': 'Thông tin đơn vị',
  'executiveCommittee.title-3': 'Chức vụ Đoàn - Đội',
  'executiveCommittee.workUnit': 'Tên đơn vị',
  'executiveCommittee.title': 'Chức danh',
  'executiveCommittee.prorogues': 'Nhiệm kỳ',
  'executive-committees.placeholder.prorogues': 'Nhập nhiệm kỳ',
  'executiveCommittee.status': 'Tình trạng hoạt động',
  'executiveCommittee.action': 'Hành động',
  'executiveCommittee.info': 'Chi tiết ban chấp hành',
  'executiveCommittee.non-delete.title': 'Không thể xóa',
  'executiveCommittee.non-delete.content': 'Không thể xóa Ban chấp hành đang hoạt động',
  'executiveCommittee.delete.title': 'Xóa Ban chấp hành',
  'executiveCommittee.delete.content': 'Bạn có muốn xóa Ban chấp hành đã chọn',
  'executiveCommittee.listOfExecutiveCommittee': 'Danh sách ban chấp hành',
  'executiveCommittee.address': 'Địa chỉ',
  // update on 11/10/2023
  'member.score': 'Số điểm',
  'member.activityHistories': 'Danh sách hoạt động',
  'member.currentLevel': 'Hạng đội viên',
  'member.meritBadges': 'Chuyên hiệu',
  'member.rewards': 'Giấy khen',
};
