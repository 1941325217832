import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerClubs: IRouter = {
  path: '/clubs',
  loader: React.lazy(() => import('./index')),
  exact: true,

  name: 'clubs.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Bookmark />,
  },
  permissionCode: PermissionCode.CLUBS_SEARCH,
  generateMenu(id) {
    return !Boolean(id);
  },
};
