export default {
  // Calendar
  'calendar.title': 'Lịch',
  'calendar.today': 'Hôm nay',

  // Meeting Schedule
  'calendar.meeting-schedule': 'Lịch họp',
  'calendar.modal-meeting-schedule.more-attendees': 'Thêm người tham dự',
  'calendar.meeting-schedule.add': 'Thêm lịch họp mới',
  'calendar.meeting-schedule.update': 'Cập nhật lịch họp',
  'calendar.meeting-schedule.update.cancellation': 'Hủy lịch',
  'calendar.meeting-schedule.info': 'Chi tiết lịch họp',
  'calendar.meeting-schedule.cancel': 'Bạn muốn hủy lịch họp này ?',
  'calendar.meeting-schedule.participants': 'Người tham dự',
  'calendar.meeting-schedule.form.host': 'Người chủ trì',
  'calendar.meeting-schedule.form.title': 'Tiêu đề',
  'calendar.meeting-schedule.form.content': 'Nội dung cuộc họp',
  'calendar.meeting-schedule.form.content.placeholder': 'Nhập nội dung',
  'calendar.meeting-schedule.form.address': 'Địa điểm',
  'calendar.meeting-schedule.form.start.time': 'Thời gian bắt đầu',
  'calendar.meeting-schedule.form.end.time': 'Thời gian kết thúc',
  'calendar.meeting-schedule.status': 'Trạng thái',
  'meeting-schedule-pageInfo.status.id':
    '{status, select, 1 {Chưa diễn ra} 2 {Đang diễn ra} 3 {Đã kết thúc} 4 {Đã hủy} other {}}',
  'meeting-schedule-pageInfo.table.code': 'Mã hội viên',
  'meeting-schedule-pageInfo.table.name': 'Tên hội viên',
  'meeting-schedule-pageInfo.table.emailAddress': 'Email',
  'meeting-schedule-pageInfo.table.memberFields': 'Lĩnh vực',
  'meeting-schedule-pageInfo.table.type': 'Đối tượng',
  'meeting-schedule-pageInfo.table.memberMarkets': 'Thị trường',
  'meeting-schedule-pageInfo.table.memberTargetCustomers': 'Khách hàng mục tiêu',
  'meeting-schedule-pageInfo.table.enterpriseScale': 'Quy mô doanh nghiệp',
  'meeting-schedule-pageInfo.table.confirm': 'Xác nhận tham gia',
  'meeting-schedule-pageInfo.table.confirm.id':
    '{status, select, 2 {Tham gia} 3 {Không tham gia} 1 {Chưa tham gia} other {}}',

  // Event Calendar
  'calendar.event.calendar': 'Hoạt động',
  'calendar.event.calendar.add': 'Thêm hoạt động mới',
  'calendar.event.calendar.update': 'Cập nhật hoạt động',
  'calendar.event.calendar.info': 'Chi tiết hoạt động',
};
