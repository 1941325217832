import React from 'react';
import { Bookmark } from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerListExecutiveCommittee: IRouter = {
  path: '/clubs/list-executive-committee/:id', ///phụ trách đội
  loader: React.lazy(() => import('./index')),
  exact: true,
  activePath: '/clubs/list-executive-committee/',
  menu: {
    icon: <Bookmark />,
  },
  generatePath(id) {
    return `/clubs/list-executive-committee/${id}`;
  },
  generateMenu(id) {
    return Boolean(id);
  },
  name: 'clubs.list-represents', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REPRESENTS_SEARCH,
};
