import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

const mainRoute = 'calendar';
const meetingScheduleRoute = 'calendar/meeting-schedule';
const eventCalendarRoute = 'calendar/event-calendar';

export const routerMeetingScheduleAdd: IRouter = {
  path: `/${meetingScheduleRoute}/add`,
  loader: React.lazy(() => import('./components/MeetingSchedule/Page')),
  exact: true,
  name: 'calendar.meeting-schedule.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_CREATE,
};

export const routerMeetingScheduleEdit: IRouter = {
  path: `/${meetingScheduleRoute}/edit/:type/:id`,
  loader: React.lazy(() => import('./components/MeetingSchedule/Page')),
  exact: true,
  name: 'calendar.meeting-schedule.update', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_UPDATE,
};

export const routerMeetingScheduleInfo: IRouter = {
  path: `/${meetingScheduleRoute}/info/:type/:id`,
  loader: React.lazy(() => import('./components/MeetingSchedule/Page/PageInfo')),
  exact: true,
  name: 'calendar.meeting-schedule.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_VIEW,
};

export const routerEventCalendarAdd: IRouter = {
  path: `/${eventCalendarRoute}/add`,
  loader: React.lazy(() => import('./components/EventCalendar/Page')),
  exact: true,
  name: 'calendar.event.calendar.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_CREATE,
};

export const routerEventCalendarEdit: IRouter = {
  path: `/${eventCalendarRoute}/edit/:id`,
  loader: React.lazy(() => import('./components/EventCalendar/Page')),
  exact: true,
  name: 'calendar.event.calendar.update', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_UPDATE,
};

export const routerEventCalendarInfo: IRouter = {
  path: `/${eventCalendarRoute}/info/:id`,
  loader: React.lazy(() => import('./components/EventCalendar/Page/PageInfo')),
  exact: true,
  name: 'calendar.event.calendar.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_VIEW,
};

// Case of using 2 calendars
// export const routerCalendar: IRouter = {
//   path: `/${mainRoute}/:tab`,
//   loader: React.lazy(() => import('./index')),
//   exact: true,
//   name: 'calendar.name', //translate here for breadcrumb and sidebar
//   menu: {
//     activePath: /calendar/i,
//     generatePath: () => {
//       return `/${meetingScheduleRoute}`;
//     },
//   },
// };

export const routerCalendar: IRouter = {
  path: `/${mainRoute}`,
  loader: React.lazy(() => import('./components/MeetingSchedule/index')),
  exact: true,
  name: 'calendar.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.SCHEDULES_SEARCH,
  menu: {
    activePath: 'calendar',
    icon: <Icon.Calendar />,
  },
};

export const routerMeetingSchedule: IRouter = {
  path: `/${meetingScheduleRoute}`,
  name: 'calendar.meeting-schedule.name', //translate here for breadcrumb and sidebar
};

export const routerEventCalendar: IRouter = {
  path: `/${eventCalendarRoute}`,
  name: 'calendar.event-calendar.name', //translate here for breadcrumb and sidebar
};
