export default {
  'action.status.param':
    '{status,select, 1 {Chưa đến} 2 {Đang diễn ra} 3 {Đã hết thúc} other {Không xác định}}',
  'action.name': 'Hoạt động',
  'action.listAction': 'Danh sách hoạt động',
  'action.add': 'Thêm hoạt động',
  'action.buttonSubmit': 'Thêm',
  'action.buttonEdit': 'Cập nhật',
  'action.time': 'Thời gian',
  'action.startDay': 'Từ ngày',
  'action.endDay': 'Đến ngày',
  'action.view': 'Chi tiết hoạt động',
  'action.edit': 'Cập nhật hoạt động',
  'action.btnViewMember': 'Danh sách đội viên',
  'action.listMember': 'Danh sách đội viên',
  'action.totalMember': 'Tổng số đội viên',
  'action.totalMemberActive': 'Số lượng Đội viên tham gia',
  'action.totalMemberNotActive': 'Số lượng Đội viên không tham gia',
  'action.title': 'Tên hoạt động',
  'action.timeAction': 'Thời gian hoạt động',
  'action.content': 'Nội dung hoat động',
  'action.meritBadgeIds': 'Chuyên hiệu',
  'action.score': 'Điểm',
  'action.clubIds': 'Đối tượng được tham gia',
  'action.validatorTitle': 'Tên hoạt động không được để trống',
  'action.validatorContent': 'Nội dung hoạt động không được để trống',
  'action.startTime': 'Thời gian bắt đầu',
  'action.endTime': 'Thời gian kết thúc',
  'action.creator': 'Người tạo',
  'action.status': 'Trạng thái',
  'action.totalMemberTable': 'Số người tham gia',
  'action.allMember': 'Tất cả Liên Đội',
  'action.delete.title': 'Xóa hoạt động',
  'action.delete.content': 'Bạn có muốn xóa hoạt động này?',
  'action.non-delete.title': 'Không thể xóa',
  'action.non-delete.content': 'Không thể xóa hoạt động đã diễn ra',
  'action.validatorTime': 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
  'actionChild.code': 'Mã Đội viên',
  'actionChild.name': 'Tên Đội viên',
  'actionChild.emailAddress': 'Email',
  'action.grade': 'Khối',
  'action.unionOfDistrict': 'Từ Quận đội',
  'action.detachment': 'Từ Liên Đội',
  'action.createSource': 'Nguồn',
  'action.tableChild.title': 'Tên hoạt động con',
  'action.tableChild.meritBadgeIds': 'Chuyên hiệu',
  'action.tableChild.score': 'Điểm',
  'action.tableChild.startTime': 'Thời gian bắt đầu',
  'action.tableChild.endTime': 'Thời gian kết thúc',
  'action.tableChild.grades': 'Khối',
  'action.tableChild.status': 'Trạng thái',
  'actionChild.status.param':
    '{status,select, 1 {Chưa xác nhận} 2 {Tham gia} 3 {Không tham gia} other {Không xác định}}',
  'actionChild.confirm': 'Trạng thái tham gia',
  'action.workunits': 'Chi đội',
  'actionChild.confirmTime': 'Thời gian tham gia',
  'action.list-member': 'Danh sách đội viên',
  'action.scoreCollected': 'Số điểm nhận được',
  'action.scoreDay': 'Ngày tích điểm',
  'laudatory.modalHistory': 'Lịch sử tích điểm',
  'action.address': 'Địa điểm',
  'action.attach': 'File đính kèm',
  'action.upload.title': 'Chọn file đính kèm',
};
