export default {
  'sponsor.name': 'Đóng góp',
  'sponsor.title': 'Danh sách đóng góp',
  'sponsor.titleDatePicker': 'Thời gian đóng góp',
  'sponsor.create': 'Thêm đóng góp',
  'sponsor.information': 'Chi tiết đóng góp',
  'sponsor.member': 'Tên đội viên',
  'sponsor.product': 'Sản phẩm đóng góp',
  'sponsor.unitPrice': 'Đơn giá (VNĐ)',
  'sponsor.unit': 'Đơn vị',
  'sponsor.quantity': 'Số lượng',
  'sponsor.user': 'Người nhận',
  'sponsor.action': 'Hành động',
  'sponsor.intoMoney': 'Thành tiền (VNĐ)',
  'sponsor.sponsorDate': 'Ngày đóng góp',
  'sponsor.code': 'Mã đội viên',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Ngày đóng',
  'sponsor.content': 'Nội dung',
  'sponsor.attach': 'Đính kèm',
  'sponsor.tagName': 'Người đóng góp',
  'sponsor.programCode': 'Mã chương trình',
  'sponsor.delete.title': 'Xóa đóng góp',
  'sponsor.delete.content': 'Bạn có muốn xóa đóng góp này?',
  'sponsors.tagName': 'Tên đội viên',
};
